import Vue from "vue";
import VueRouter from "vue-router";
import {
  trackPage
} from "@/services/logging";

const AppConfig = JSON.parse(localStorage.getItem("AppConfig"));

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "collaboration",
    component: () => import("../views/Collaboration/Collaboration.vue"),
  },
  {
    path: "/collaboration/:token/:pluginToken",
    name: "collaboration",
    component: () => import("../views/Collaboration/Collaboration.vue"),
  },
  {
    path: "/entry/:sharedId",
    name: "collaboration",
    component: () => import("../views/Collaboration/Collaboration.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: AppConfig.BaseUrl,
  routes,
});

router.beforeEach((to, from, next) => {
  trackPage(to.name);
  if (to.params.token) {
    sessionStorage.setItem("token", to.params.token);
  }
  if ((!sessionStorage.getItem("token") && to.name !== "login")) {
    const clientId = "&client_id=" + AppConfig.ApplicationId;
    const scope = "&scope=Tenant-" + AppConfig.Tenant;
    const redirectUrl = "&redirect_uri=" + AppConfig.RedirectUrl;
    var state = null;
    if (to.path && to.path.indexOf('/entry/') >= 0 && to.params && to.params.sharedId) {
      state = "&state=" + JSON.stringify({
        sharedId: to.params.sharedId
      });
    }
    let url =
      AppConfig.AuthorizationUrl +
      "?response_type=token" +
      clientId +
      scope +
      redirectUrl;
    if (state != null) {
      url += state;
    }
    window.location.href = url;
  } else {
    next();
  }
});
export default router;